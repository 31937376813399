import {Injectable} from '@angular/core';
import {ApiService} from '../shared-services/api.service';
import {AlertService} from '../shared-services/alert.service';
import {ApiUrlService} from '../shared-services/api-url.service';
import {Router} from '@angular/router';
import {NbDateService} from '@nebular/theme';
import {API_URLS} from '../../constants/api-urls';
import {Observable} from 'rxjs';
import {HttpParams} from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class EmployeeService {

  serviceUrl: string = '';


  constructor(
    private apiService: ApiService,
    private apiUrlService: ApiUrlService,
    private alertService: AlertService,
    private router: Router,
    private dateService: NbDateService<Date>,
  ) {

    this.apiUrlService.getUrl().subscribe((x) => {
      this.serviceUrl = x.url;
    });

  }

  getProfilePicture(employeeID: any, eid?: undefined): Observable<any> {
    const url = `${this.serviceUrl}${API_URLS.EMPLOYEE.DOWNLOAD_URL}?employeeID=${employeeID}&fileType=picture&e_id=${eid}`;
    return this.apiService.get(url);
  }

  getProfilePictureForProfile(employeeId: any, fileType: any): Observable<any> {
    const params = new HttpParams()
      .set('employeeId', employeeId)
      .set('fileType', fileType);
    const url = `${this.serviceUrl}${API_URLS.EMPLOYEE.GET_PROFILE_PICTURE}`;
    return this.apiService.get(url, params);
  }

  getCV(employeeID: any, eid?: undefined): Observable<any> {
    const url = `${this.serviceUrl}${API_URLS.EMPLOYEE.DOWNLOAD_URL}?employeeID=${employeeID}&fileType=cv&e_id=${eid}`;
    return this.apiService.get(url);
  }

  getCertificate(employeeID, eid): Observable<any> {
    const url = `${this.serviceUrl}${API_URLS.EMPLOYEE.DOWNLOAD_URL}?employeeID=${employeeID}&fileType=certificate&e_id=${eid}`;
    return this.apiService.get(url);
  }

  getEmployeeById(employeeID: number): Observable<any> {
    const params = new HttpParams()
      .set('employeeId', employeeID);
    return this.apiService.get(this.serviceUrl + '/employee-by-employee-id', params);
  }

  getEmployeeByIdForRole(employeeID: number): Observable<any> {
    const params = new HttpParams()
      .set('employeeId', employeeID);
    return this.apiService.get(this.serviceUrl + '/employee-by-employee-id-for-role', params);
  }

  getEmployeeInfoById(employeeID: number): Observable<any> {
    const params = new HttpParams()
      .set('employeeId', employeeID);
    return this.apiService.get(this.serviceUrl + '/employee-by-id', params);
  }

  getEmployeeByIdForUser(employeeID: number): Observable<any> {
    const url = `${this.serviceUrl}${API_URLS.USER.GET_EMPLOYEE}/${employeeID}`;
    return this.apiService.get(url);
  }

  generateOrganogram(departmentID: number): Observable<any> {
    const url = `${this.serviceUrl}${API_URLS.ORGANOGRAM}/${departmentID}`;
    return this.apiService.get(url);
  }

  saveJobDescription(item): Observable<any> {
    const url = `${this.serviceUrl}${API_URLS.EMPLOYEE.JOB_DESCRIPTION}`;
    return this.apiService.post(url, item);
  }

  saveEmployeeAdmin(employee): Observable<any> {
    const url = `${this.serviceUrl}${API_URLS.EMPLOYEE.SAVE_EMPLOYEE_ADMIN}`;
    return this.apiService.post(url, employee);
  }

  getBasicInfo(): Observable<any> {
    const url = `${this.serviceUrl}${API_URLS.EMPLOYEE.BASIC_INFO}`;
    return this.apiService.get(url);
  }

  getAllDepartments(): Observable<any> {
    const url = `${this.serviceUrl}${API_URLS.DEPARTMENT.BASIC_URL}`;
    return this.apiService.get(url);
  }

  getJobDescription(id: number): Observable<any> {
    const url = `${this.serviceUrl}${API_URLS.EMPLOYEE.JOB_DESCRIPTION}/${id}`;
    return this.apiService.get(url);
  }

  getAllDesignationByDepartment(departmentId: number): Observable<any> {
    const url = `${this.serviceUrl}${API_URLS.DEPARTMENT.DESIGNATION_BY_DEPARTMENT + departmentId}`;
    return this.apiService.get(url);
  }

  getAddress(id: number): Observable<any> {
    const url = `${this.serviceUrl}${API_URLS.EMPLOYEE.ADDRESS}/${id}`;
    return this.apiService.get(url);
  }

  getEmployeeChangeRequest(): Observable<any> {
    const url = `${this.serviceUrl}${API_URLS.EMPLOYEE.EMPLOYEE_INFO_CHANGE_REQUEST}`;
    return this.apiService.get(url);
  }

  getEmployeeChangeRequestCount(): Observable<any> {

    const url = `${this.serviceUrl}${API_URLS.EMPLOYEE.EMPLOYEE_INFO_CHANGE_REQUEST_COUNT}`;
    return this.apiService.get(url);

  }

}
