import {Injectable} from '@angular/core';
import Swal, {SweetAlertResult} from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor() {
  }

  showWarn(message: string) {
    Swal.fire(message);
  }

  showSuccessAlert(method: string, message: string) {
    Swal.fire('Successfully ' + method + ' ' + message + ' data', '', 'success');
  }

  showSuccessAlertMessage(message: string) {
    Swal.fire(message);
  }

  showErrorAlert(message: string, method?: string) {
    if (method) {
      Swal.fire('Failed to ' + method + ' ' + message + ' data', '', 'error');
    }
    Swal.fire(message, '', 'error');
  }

  showGeneralErrorAlert() {
    Swal.fire('Something went wrong', '', 'error');
  }
  hideAlert() {
    Swal.close();
  }

  showWarningAlert(message: any): Promise<any> {
    return Swal.fire({
      title: message == null ? 'Are you sure?' : message,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Confirm',
      confirmButtonColor: '#baf604',
      cancelButtonText: 'Cancel',
      cancelButtonColor: '#ef0202',
    });
  }


}
